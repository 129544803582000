import React, { useContext, useCallback, useEffect, useState, useRef, memo } from 'react';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Web3ManageContext } from 'providers/Web3ManageProvider';
import { selectIsAuth, selectActiveStatus, selectIsRequiredMetaMask } from 'redux/selectors';
import metamaskImg from 'assets/img/metamask-32.png';
import { sendMetaMaskRequest } from 'api/matamask';
import Api from 'api/index';

const useStyles = makeStyles({
  button: {
    color: '#ddd',
    backgroundColor: '#313131',
    '&:hover': {
      backgroundColor: '#484848'
    }
  },
});

const ConnectWalletButton = React.forwardRef(({ size = 'medium', isNavbar, ...root }, ref) => {
  const { activate, deactivate, account } = useContext(Web3ManageContext);
  const classes = useStyles();
  const [isBusy, setIsBusy] = useState(false);
  const isBusyRef = useRef(false);
  const isLoggedIn = useSelector(selectIsAuth);
  const botActiveStatus = useSelector(selectActiveStatus);
  const [activating, setActivating] = useState(false);
  const isRequiredMMask = useSelector(selectIsRequiredMetaMask);
  const handleActivate = useCallback(() => {
    if (isLoggedIn) {
      setActivating(true);
    } else {
      activate();
    }
  }, []);

  const handleInstall = () => {
    window.open("https://metamask.io/", "_blank");
  };

  const handleOkConnect = useCallback(() => {
    activate();
  }, [activate]);

  const handleCancelConnect = useCallback(() => {
    setActivating(false);
  }, []);

  const handleSendMetaMask = useCallback(
    async account => {
      return sendMetaMaskRequest(account)
        .then(res => {
          if (res.status == 'error') deactivate();
        })
        .catch(() => {
          deactivate();
        })
        .finally(() => {
          setActivating(false);
        });
    },
    [deactivate],
  );

  useEffect(() => {
    if (!isLoggedIn || isBusy) return;
    if (!botActiveStatus && account) {
      setIsBusy(true);
      handleSendMetaMask(account).finally(() => {
        setTimeout(() => {
          setIsBusy(false);
        }, 5000);
      });
    } else if (!account && botActiveStatus && isRequiredMMask) {
      setIsBusy(true);
      Api.stopBotCore().finally(() => {
        setTimeout(() => {
          setIsBusy(false);
        }, 5000);
      });
    }
  }, [account]);

  const height = size === 'large' ? 26 : 20;

  return (
    <>
      {window.ethereum && isRequiredMMask ? <Box ref={ref} {...root}>
        {account && !activating ? (
          <Button
            size={size}
            variant="contained"
            color="primary"
            onClick={deactivate}
            fullWidth
            style={{ textTransform: 'none' }}
            startIcon={
              isLoggedIn && isBusy ? (
                <CircularProgress color="inherit" size={height} />
              ) : (
                <Avatar style={{ borderRadius: 0, width: height, height: height }} src={metamaskImg} />
              )
            }
          >
            {account.slice(0, 6) + ' ... ' + account.slice(-4)}
          </Button>
        ) : (
          <Button
            className={isNavbar ? classes.button : {}}
            size={size}
            variant="contained"
            onClick={handleActivate}
            color="primary"
            fullWidth
            style={{ textTransform: 'none' }}
            startIcon={
              isLoggedIn && isBusy ? (
                <CircularProgress color="inherit" size={height} />
              ) : (
                <Avatar style={{ borderRadius: 0, width: height, height: height }} src={metamaskImg} />
              )
            }
          >
            Connect with MetaMask
          </Button>
        )}
      </Box>
        : !window.ethereum && isRequiredMMask ?
          <Box ref={ref} {...root}>
            <Button
              className={isNavbar ? classes.button : {}}
              size={size}
              variant="contained"
              onClick={handleInstall}
              color="primary"
              fullWidth
              style={{ textTransform: 'none' }}
              startIcon={
                isLoggedIn && isBusy ? (
                  <CircularProgress color="inherit" size={height} />
                ) : (
                  <Avatar style={{ borderRadius: 0, width: height, height: height }} src={metamaskImg} />
                )
              }
            >
              Install MetaMask
            </Button>
          </Box>
          : null
      }

      <Dialog maxWidth="xs" aria-labelledby="confirmation-dialog-title" open={activating}>
        <DialogTitle id="confirmation-dialog-title">Confirm MetaMask connection</DialogTitle>
        <DialogContent>
          <Typography>Proceed to connect your MetaMask wallet? After connecting, this will be the only way to login to this Gunbot instance.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConnect} color="default">
            Cancel
          </Button>
          <Button autoFocus onClick={handleOkConnect} color="primary">
            Connect
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default memo(ConnectWalletButton);
