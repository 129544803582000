import React, { useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AlertIcon from './svg/DismissibleAlertIcon';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

import { Event, SocketContext } from 'SocketIO';

const useStyle = makeStyles({
  root: {
    backgroundColor: 'rgb(60, 38, 1)',
    padding: '5px 10px',
    display: 'flex',
    height: 36,
    padding: '0px 28px',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
    height: 20,
  },
  content: {
    margin: 0,
    minWidth: 0,
    display: 'inline-block',
    color: 'rgb(184 184 184)',
    fontSize: '14px',
  },
  viewMore: {
    color: '#d4a30a',
    marginLeft: 10,
    color: 'rgb(240, 185, 11)',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  moreArrow: {
    margin: '2px 0px 0px 4px',
    minWidth: '0px',
    color: 'rgb(240, 185, 11)',
    fontSize: '12px',
    fill: 'rgb(240, 185, 11)',
    width: '1em',
    height: '1em',
  },
});

let timer = null;

function MainAlert() {
  const classes = useStyle();
  const socket = useContext(SocketContext);
  const [mainAlert, setMainAlert] = useState();
  const isMobile = window.innerWidth <= 960;

  const [display, setDisplay] = useState(true);

  const urlContainsSetup = window.location.href.includes('setup');


  const handleClose = () => {
    localStorage.setItem('mainAlert', mainAlert.message);
    setDisplay(false);
  };

  useEffect(() => {
    timer = setTimeout(() => {
      timer = null;
      socket.emit('GET_MAIN_ALERT');
    }, 5000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const handleMainAlert = useCallback(mainAlert => {
    setMainAlert(mainAlert);
  }, []);

  useEffect(() => {
    const message = localStorage.getItem('mainAlert');
    setDisplay(mainAlert && message !== mainAlert.message);
  }, [mainAlert]);

  const handleLogin = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    socket.emit('GET_MAIN_ALERT');
  }, []);

  return (
    <>
      <Event event={'login'} handler={handleLogin} />
      <Event event={'MAIN_ALERT_CHANGED'} handler={handleMainAlert} />
      {mainAlert && display && !isMobile && !urlContainsSetup && (
        <div className={classes.root}>
          <div className={classes.icon}>
            <AlertIcon />
          </div>
          <div style={{ flex: 'auto', display: 'flex' }}>
            <div className={classes.content}>{mainAlert?.message}</div>
            <a className={classes.viewMore} href={mainAlert?.link} target={'_blank'}>
              {'View More '}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" className={classes.moreArrow}>
                <path d="M8.825 8L3.95 12.95 5 14l6-6-6-6-1.05 1.05L8.825 8z" fill="currentColor"></path>
              </svg>
            </a>
          </div>
          <IconButton style={{ padding: 4 }} onClick={handleClose}>
            <Clear color="inherit" fontSize="small" />
          </IconButton>
        </div>
      )}
    </>
  );
}

export default MainAlert;
